import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";
import LogoDesktop from "./logo.svg"
import { Icon } from '@types';

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <LogoDesktop />
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .logo {
    width: 500px;
  }
  ${mediaqueries.tablet`
    .logo {
      width: 350px;
    }
  `};

  ${mediaqueries.phablet`
    .logo {
      width: 250px;
    }
  `};
`;
